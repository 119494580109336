.title {
  margin-bottom: 10px;
  color: #888;
  font-size: 1.1em;
}

.subtitle {
  margin-bottom: 30px;
  color: #aaa;
  font-size: 0.75em;
}

/* Optional: Ensure the button is vertically centered in case of any padding/margin discrepancies */
.analyseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.customFeedback {
  width: 100%;
  margin-top: 1rem;
  color: #c2c2c2; /* Bootstrap "success" color, adjust as needed for different types of messages */
}

.textAreaCaption {
  color: #444;
  width: 100%;
  text-align: left;
  display: block;
  font-size: 0.6em;
}

.textAreaCustom::placeholder {
  color: #bbb !important;
  opacity: 1; /* Ensure the color is not transparent */
}