.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 50px;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-size: 1.75rem;
  font-weight: bold;
  margin: 0;
  color: rgb(119, 119, 119);
}

h2 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 30;
  color: rgb(192, 192, 192);
}